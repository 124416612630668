import type { ProjectResource, GitBranchResource } from "~/client/resources";
import { HasVersionControlledPersistenceSettings } from "~/client/resources";
import { repository } from "~/clientInstance";
import type { SpecifiedBranch, BranchSpecifier } from "../components/ProjectsRoutes/BranchSpecifier";
import { UseDefaultBranch, ShouldUseDefaultBranch } from "../components/ProjectsRoutes/BranchSpecifier";

function lastAccessedBranchStore() {
    const LocalStorageKey = "octoLastAccessedBranch";

    const buildKey = (projectIdOrSlug: string) => {
        return `${LocalStorageKey}-${projectIdOrSlug}`;
    };

    const save = async (project: ProjectResource, branch: SpecifiedBranch): Promise<void> => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored branches for VCS projects");
        }

        localStorage.setItem(buildKey(project.Id), branch);
    };

    const get = async (project: ProjectResource): Promise<GitBranchResource> => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored branches for VCS projects");
        }

        const key = buildKey(project.Id);
        const storedBranch = localStorage.getItem(key) ?? UseDefaultBranch;

        if (!ShouldUseDefaultBranch(storedBranch)) {
            try {
                return await repository.Projects.getBranch(project, storedBranch);
            } catch (e) {
                localStorage.removeItem(key);
            }
        }
        if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
        return repository.Projects.getBranch(project, project.PersistenceSettings.DefaultBranch);
    };

    const getBranchName = (project: ProjectResource): BranchSpecifier => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored branches for VCS projects");
        }

        const key = buildKey(project.Id);
        return localStorage.getItem(key) ?? UseDefaultBranch;
    };

    return {
        save,
        get,
        getBranchName,
    };
}

const lastAccessedBranch = lastAccessedBranchStore();

export { lastAccessedBranch };
