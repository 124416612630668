export type GitCommit = string;
export type GitBranch = string;
export type GitTag = string;
export type GitRef = GitCommit | GitBranch | GitTag;
export enum GitRefType {
    Commit = "Commit",
    Branch = "Branch",
    Tag = "Tag",
    Unknown = "Unknown",
}
const refBranchPrefix = "refs/heads/";
const refTagPrefix = "refs/tags/";

export function getGitRefType(ref?: GitRef): GitRefType | undefined {
    if (!ref) {
        return undefined;
    } else if (isGitBranch(ref)) {
        return GitRefType.Branch;
    } else if (isGitTag(ref)) {
        return GitRefType.Tag;
    } else if (isGitCommit(ref)) {
        return GitRefType.Commit;
    }
    return GitRefType.Unknown;
}

export function isGitBranch(ref: GitRef | string | undefined): ref is GitBranch {
    return !!ref && ref.startsWith(refBranchPrefix);
}

export function isGitTag(ref: GitRef | string | undefined): ref is GitTag {
    return !!ref && ref.startsWith(refTagPrefix);
}

export function isGitCommit(ref: GitRef | string | undefined): ref is GitCommit {
    return !!ref && /^[0-9a-fA-F]{7,40}$/i.test(ref);
}

export function toGitBranchShort(branch: GitBranch) {
    return branch.substr(refBranchPrefix.length);
}

export function toGitTagShort(tag: GitTag) {
    return tag.substr(refTagPrefix.length);
}

export function toGitCommitShort(commit: GitCommit) {
    return commit.substring(0, 7);
}

export function toGitRefShort(ref: GitRef) {
    if (!ref) {
        return null;
    }

    if (isGitBranch(ref)) return toGitBranchShort(ref);
    if (isGitTag(ref)) return toGitTagShort(ref);
    if (isGitCommit(ref)) return toGitCommitShort(ref);

    // Unable to determine the type based on prefix.
    // It may already be short so just return as-is
    return ref;
}

export function toGitBranch(ref: GitRef | string): GitBranch {
    if (isGitBranch(ref)) {
        return ref;
    }
    return `${refBranchPrefix}${ref}`;
}

export interface GitReference {
    GitRef?: GitRef;
    GitCommit?: GitCommit;
}

export interface IHaveGitReference {
    VersionControlReference: GitReference;
}

// This value is the "default default" branch
//
// we had the magic string "master" showing up in a few places, which is not ideal.
// we need a "default default" to decide what will show up in the ui as a convenience when
// no other value has been selected, e.g: when choosing what your default branch will be
// in project version control settings.
//
// If possible, using this const should be avoided in favour of:
// - VersionControlSettings.DefaultBranch
// - ProjectContextState.branch
// whichever suits the context.
//
// TODO: decide if we want the user to be able to configure this at a server-wide level.
export const branchToShowByDefault = toGitBranch("main");

const getBasePathToShowByDefault = (projectName: String) => {
    return `.octopus`;
};

export { getBasePathToShowByDefault };
